import { SvgIcon } from 'common/icons/typings';
import React from 'react';

/* eslint-disable max-len */
export const ThePowerLogoIcon: React.FC<SvgIcon> = (props) => (
  <svg {...props} width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.37019 7.96455L16.822 6.23802L12.2636 12.9297L12.245 12.8985L12.1303 13.0913L3.37019 7.96455ZM2.94559 8.47202L11.7787 13.6415L11.6281 13.8626H11.6713L7.08202 21.5742L2.94559 8.47202ZM7.56383 22.0404L12.245 14.1743L16.9263 22.0404H7.56383ZM16.9263 22.6928H17.1738L17.1738 34.4009L7.67483 22.6928H16.9263ZM7.03132 22.9352L16.136 34.1573L1.28295 25.589L6.79415 22.8295L6.89815 23.1589L7.03132 22.9352ZM6.48088 22.2567L0.978575 25.0118L2.54602 9.37349L6.54501 22.0404H6.30534L6.48088 22.2567ZM4.40571 7.17386L17.1738 1.19231V5.45165L16.8224 5.40684L16.931 5.56624L4.40571 7.17386ZM17.8262 5.45134V1.19089L30.6815 7.17407L18.0693 5.56584L18.178 5.40618L17.8262 5.45134ZM18.1776 6.23736L31.721 7.96434L22.8704 13.0925L22.755 12.8985L22.7364 12.9297L18.1776 6.23736ZM17.5 6.40154L22.138 13.2102H12.862L17.5 6.40154ZM17.5 21.7287L12.8188 13.8626H22.1812L17.5 21.7287ZM18.0737 22.0404L22.755 14.1743L27.4362 22.0404H18.0737ZM18.0737 22.6928H27.3252L17.8262 34.4009L17.8262 22.6928H18.0737ZM23.2221 13.6427L32.1485 8.4707L27.921 21.5793L23.3287 13.8626H23.3719L23.2221 13.6427ZM18.864 34.1573L27.9687 22.9352L28.0988 23.1538L28.2037 22.8284L33.717 25.589L18.864 34.1573ZM28.4579 22.0404L32.544 9.36996L34.0249 25.0135L28.5191 22.2567L28.6947 22.0404H28.4579Z"
      fill="url(#paint0_linear_6947_19925)"
    />
    <path
      d="M3.37019 7.96455L16.822 6.23802L12.2636 12.9297L12.245 12.8985L12.1303 13.0913L3.37019 7.96455ZM2.94559 8.47202L11.7787 13.6415L11.6281 13.8626H11.6713L7.08202 21.5742L2.94559 8.47202ZM7.56383 22.0404L12.245 14.1743L16.9263 22.0404H7.56383ZM16.9263 22.6928H17.1738L17.1738 34.4009L7.67483 22.6928H16.9263ZM7.03132 22.9352L16.136 34.1573L1.28295 25.589L6.79415 22.8295L6.89815 23.1589L7.03132 22.9352ZM6.48088 22.2567L0.978575 25.0118L2.54602 9.37349L6.54501 22.0404H6.30534L6.48088 22.2567ZM4.40571 7.17386L17.1738 1.19231V5.45165L16.8224 5.40684L16.931 5.56624L4.40571 7.17386ZM17.8262 5.45134V1.19089L30.6815 7.17407L18.0693 5.56584L18.178 5.40618L17.8262 5.45134ZM18.1776 6.23736L31.721 7.96434L22.8704 13.0925L22.755 12.8985L22.7364 12.9297L18.1776 6.23736ZM17.5 6.40154L22.138 13.2102H12.862L17.5 6.40154ZM17.5 21.7287L12.8188 13.8626H22.1812L17.5 21.7287ZM18.0737 22.0404L22.755 14.1743L27.4362 22.0404H18.0737ZM18.0737 22.6928H27.3252L17.8262 34.4009L17.8262 22.6928H18.0737ZM23.2221 13.6427L32.1485 8.4707L27.921 21.5793L23.3287 13.8626H23.3719L23.2221 13.6427ZM18.864 34.1573L27.9687 22.9352L28.0988 23.1538L28.2037 22.8284L33.717 25.589L18.864 34.1573ZM28.4579 22.0404L32.544 9.36996L34.0249 25.0135L28.5191 22.2567L28.6947 22.0404H28.4579Z"
      fill="url(#paint1_linear_6947_19925)"
    />
    <path
      d="M3.37019 7.96455L16.822 6.23802L12.2636 12.9297L12.245 12.8985L12.1303 13.0913L3.37019 7.96455ZM2.94559 8.47202L11.7787 13.6415L11.6281 13.8626H11.6713L7.08202 21.5742L2.94559 8.47202ZM7.56383 22.0404L12.245 14.1743L16.9263 22.0404H7.56383ZM16.9263 22.6928H17.1738L17.1738 34.4009L7.67483 22.6928H16.9263ZM7.03132 22.9352L16.136 34.1573L1.28295 25.589L6.79415 22.8295L6.89815 23.1589L7.03132 22.9352ZM6.48088 22.2567L0.978575 25.0118L2.54602 9.37349L6.54501 22.0404H6.30534L6.48088 22.2567ZM4.40571 7.17386L17.1738 1.19231V5.45165L16.8224 5.40684L16.931 5.56624L4.40571 7.17386ZM17.8262 5.45134V1.19089L30.6815 7.17407L18.0693 5.56584L18.178 5.40618L17.8262 5.45134ZM18.1776 6.23736L31.721 7.96434L22.8704 13.0925L22.755 12.8985L22.7364 12.9297L18.1776 6.23736ZM17.5 6.40154L22.138 13.2102H12.862L17.5 6.40154ZM17.5 21.7287L12.8188 13.8626H22.1812L17.5 21.7287ZM18.0737 22.0404L22.755 14.1743L27.4362 22.0404H18.0737ZM18.0737 22.6928H27.3252L17.8262 34.4009L17.8262 22.6928H18.0737ZM23.2221 13.6427L32.1485 8.4707L27.921 21.5793L23.3287 13.8626H23.3719L23.2221 13.6427ZM18.864 34.1573L27.9687 22.9352L28.0988 23.1538L28.2037 22.8284L33.717 25.589L18.864 34.1573ZM28.4579 22.0404L32.544 9.36996L34.0249 25.0135L28.5191 22.2567L28.6947 22.0404H28.4579Z"
      stroke="#F5F5F7"
      strokeWidth="0.652434"
    />
    <defs>
      <linearGradient id="paint0_linear_6947_19925" x1="17.5" y1="0" x2="17.5" y2="36" gradientUnits="userSpaceOnUse">
        <stop offset="0.11319" stopColor="#1D2A52" />
        <stop offset="0.455955" stopColor="#1288EB" />
        <stop offset="0.868343" stopColor="#1D2A52" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6947_19925"
        x1="1.83428"
        y1="18"
        x2="33.3392"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1D2A52" />
        <stop offset="0.25" stopColor="#1D2A52" stopOpacity="0.35" />
        <stop offset="0.520833" stopColor="#1D2A52" stopOpacity="0" />
        <stop offset="0.807292" stopColor="#1D2A52" stopOpacity="0.35" />
        <stop offset="1" stopColor="#1D2A52" />
      </linearGradient>
    </defs>
  </svg>
);
