const EXPLORER_THEPOWER_URL = process.env.REACT_APP_EXPLORER_THEPOWER_URL!;
const WALLET_THEPOWER_URL = process.env.REACT_APP_WALLET_THEPOWER_URL!;
const HUB_THEPOWER_URL = process.env.REACT_APP_HUB_THEPOWER_URL!;
const FAUCET_THEPOWER_URL = process.env.REACT_APP_FAUCET_THEPOWER_URL!;

export default {
  EXPLORER_THEPOWER_URL,
  WALLET_THEPOWER_URL,
  HUB_THEPOWER_URL,
  FAUCET_THEPOWER_URL,
};
